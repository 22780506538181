<script>
  import { link } from 'svelte-routing'
  import { Nav } from 'sveltestrap'

  export let url = '?page=%d'
  export let perPage
  export let totalPages
  export let currentPage

  let pages = []
  let nextPage = null
  let prevPage = null
  let firstPage = 1
  let lastPage = null

  const formatUrl = (url, page) => url.replace(/%d/, page)

  $: {
    const newPage = parseInt(currentPage) + 1
    nextPage = newPage <= totalPages ? newPage : null
  }
  $: {
    const newPage = parseInt(currentPage) - 1
    prevPage = newPage > 0 ? newPage : null
  }
  $: {
    pages = Array.from({ length: totalPages }, (v, i) => i + 1)
  }
  $: lastPage = totalPages
  $: currentPage = parseInt(currentPage)
</script>

<Nav>
  <ul class="pagination">
    {#if firstPage !== currentPage}
      <li class="page-item">
        <a href={formatUrl(url, firstPage)} class="page-link" use:link>First</a>
      </li>
    {/if}

    {#if prevPage}
      <li class="page-item">
        <a href={formatUrl(url, prevPage)} class="page-link" use:link>Prev</a>
      </li>
    {/if}

    {#if pages.length > 0}
      {#each pages as page}
        {#if page === currentPage}
          <li class="page-item active">
            <span class="page-link">
              {page}
              <span class="sr-only">(current)</span>
            </span>
          </li>
        {:else}
          <li class="page-item">
            <a href={formatUrl(url, page)} class="page-link" use:link>{page}</a>
          </li>
        {/if}
      {/each}
    {/if}

    {#if nextPage}
      <li class="page-item">
        <a href={formatUrl(url, nextPage)} class="page-link" use:link>Next</a>
      </li>
    {/if}

    {#if lastPage !== currentPage}
      <li class="page-item">
        <a href={formatUrl(url, lastPage)} class="page-link" use:link>Last</a>
      </li>
    {/if}
  </ul>
</Nav>
