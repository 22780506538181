export default class GlobalStorage {
  constructor(namespace) {
    this.namespace = namespace
  }

  get storage() {
    return window[this.namespace]
      ? window[this.namespace]
      : (window[this.namespace] = {})
  }

  set(key, value) {
    this.storage[key] = value
  }

  get(key, defaultValue) {
    if (!this.isMember(key) && arguments.length === 2)
      this.set(key, defaultValue)
    return this.storage[key]
  }

  getAndSetIfNotExists(key, defaultValue) {
    if (!this.isMember(key)) this.set(key, defaultValue)
    return this.get(key)
  }

  delete(key) {
    delete this.storage[key]
  }

  keys() {
    return Object.keys(this.storage)
  }

  values() {
    return Object.values(this.storage)
  }

  entries() {
    return Object.entries(this.storage)
  }

  data() {
    return this.entries().reduce(
      (object, [key, value]) => ({ ...object, [key]: value }),
      {}
    )
  }

  isMember(key) {
    return this.storage.hasOwnProperty(key)
  }
}
