<script>
  export let autocomplete
  export let domNode
  export let handleBlur = () => {}
  export let id
  export let type = 'text'
  export let value
  export let className

  const ENTER_KEY = 13

  const handleKeydown = event => {
    if (event.keyCode === ENTER_KEY) event.target.blur()
  }
</script>

<style>
  input:focus {
    background-color: ivory;
  }
</style>

{#if type === 'text'}
  <input
    type="text"
    class={className}
    {id}
    {autocomplete}
    bind:this={domNode}
    bind:value
    on:blur={handleBlur}
    on:keydown={handleKeydown} />
{/if}
