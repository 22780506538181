<script context="module">
  import { writable } from 'svelte/store'
  import GlobalStorage from 'utils/GlobalStorage'

  const STORAGE_KEY = 'Toaster'

  const createToaster = () => GlobalStorage.get(STORAGE_KEY, writable(null))

  export const Toaster = createToaster()
  export const setToast = toast => Toaster.set(toast)
</script>

<script>
  import Notifications from '@beyonk/svelte-notifications'

  export let toast

  let themes = {
    danger: '#E74C3C',
    success: '#18BC9C',
    warning: '#F39C12',
    info: '#3498DB',
    default: '#95a5a6'
  }
</script>

<Notifications bind:this={toast} {themes} />
