<script>
  import clsx from 'clsx'
  import { FormFeedback } from 'sveltestrap'

  let className
  export { className as class }
  export let autocomplete = 'off'
  export let id = ''
  export let name
  export let placeholder = ''
  export let type
  export let label = ''

  export let value = ''
  export let checked = false

  export let error
  export let handleBlur
  export let invalid
  export let valid

  let defaultClass = type == 'checkbox' ? 'form-check-input' : 'form-control'

  $: className = clsx(className, defaultClass)
  $: isValid = valid !== undefined ? valid : error === null
  $: isInvalid = invalid !== undefined ? invalid : error
</script>

{#if type === 'text'}
  <input
    type="text"
    {id}
    {name}
    {placeholder}
    {autocomplete}
    class={className}
    class:is-valid={isValid}
    class:is-invalid={isInvalid}
    bind:value
    on:blur={handleBlur} />
{:else if type === 'email'}
  <input
    type="email"
    {id}
    {name}
    {placeholder}
    {autocomplete}
    class={className}
    class:is-valid={isValid}
    class:is-invalid={isInvalid}
    bind:value
    on:blur={handleBlur} />
{:else if type === 'password'}
  <input
    type="password"
    {id}
    {name}
    {placeholder}
    {autocomplete}
    class={className}
    class:is-valid={isValid}
    class:is-invalid={isInvalid}
    bind:value
    on:blur={handleBlur} />
{:else if type === 'checkbox'}
  <div class="form-check">
    <label for={id}>
      <input
        type="checkbox"
        {id}
        {name}
        {placeholder}
        {autocomplete}
        class={className}
        class:is-valid={isValid}
        class:is-invalid={isInvalid}
        bind:checked
        on:blur={handleBlur} />
      {label}
    </label>
  </div>
{/if}
<FormFeedback valid={false}>
  {#if error}
    {#if typeof error === 'string'}
      {error}
    {:else}
      <ul class="list-inline p-0 m-0">
        {#each error as errorMessage}
          <li>{errorMessage}</li>
        {/each}
      </ul>
    {/if}
  {/if}
</FormFeedback>
