<script>
  import { Link } from 'svelte-routing'
  import clsx from 'clsx'

  let className
  export { className as class }
  export let to = '#'
  export let replace = false
  export let state = {}
  export let isActive

  $: classes = ['nav-link', className]

  const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
    let active
    if (typeof isActive === 'function') {
      active = isActive(location)
    } else {
      active = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent
    }

    return {
      class: clsx(classes, { active })
    }
  }
</script>

<Link {to} {replace} {state} {getProps}>
  <slot />
</Link>
