import ApiClient from './ApiClient'
import camelcase from 'camelcase-keys'

const getToken = () =>
  document.querySelectorAll('meta[name="csrf-token"]')[0].content

const ssl = () => document.location.protocol === 'https:'

const defaultOptions = { ssl: ssl() }

export const createClient = (options = {}) =>
  new ApiClient({ ...defaultOptions, ...options })

export const createClientWithToken = (options = {}) =>
  createClient({ ...options, token: getToken() })

export const createErrorHandler = (callback) => response => {
  let errorArray
  let errorObject

  if (response instanceof Error) {
    errorArray = ['ネットワークエラーが発生しました。通信環境をご確認の上、再度お試しください。']
  } else if (response.status >= 500) {
    errorArray = ['一時的に接続がしづらくなっております。しばらくしてから再度お試しください。']
  } else if (response.data.errors) {
    let errors = response.data.errors

    if (Array.isArray(errors)) {
      errorArray = errors
    } else {
      errorArray = Object.values(errors).flat()
      errorObject = camelcase(errors)
    }
  } else {
    errorArray = ['エラーが発生しました。']
  }

  if (!errorObject)
    errorObject = { null: errorArray }

  return callback(errorArray, errorObject)
}

export const camelizer = response => ({
  ...response,
  data: camelcase(response.data, { deep: true })
})
