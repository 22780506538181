<script>
  export let domNode
  export let handleClick = () => {}
  export let id
  export let value
  export let className
</script>

<style>
  div {
    cursor: text;
    height: auto;
    /* bootstrap の .form-control の height の値をここで再利用 */
    min-height: calc(1.5em + 0.75rem + 2px);
  }
  div:hover {
    background-color: aliceblue;
  }
</style>

<div class={className} {id} bind:this={domNode} on:click={handleClick}>
  {value}
</div>
