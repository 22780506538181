<script>
  import { createEventDispatcher, tick } from 'svelte'
  import { FormFeedback } from 'sveltestrap'
  import clsx from 'clsx'
  import Readonly from './Readonly.svelte'
  import Editable from './Editable.svelte'

  export let value

  export let id = ''
  export let autocomplete = 'off'
  export let error = ''

  let component = Readonly
  let domNode

  let className
  $: className = clsx('form-control', { 'is-invalid': !!error })

  const dispatch = createEventDispatcher()

  const handleClick = async _event => {
    component = Editable
    await tick()
    domNode.focus()
  }

  const handleBlur = _event => {
    component = Readonly
    dispatch('edited')
  }
</script>

<svelte:component
  this={component}
  {className}
  bind:domNode
  bind:value
  {id}
  {autocomplete}
  {handleClick}
  {handleBlur} />
<FormFeedback valid={false}>
  {#if error}
    {#if typeof error === 'string'}
      {error}
    {:else}
      <ul class="list-inline p-0 m-0">
        {#each error as errorMessage}
          <li>{errorMessage}</li>
        {/each}
      </ul>
    {/if}
  {/if}
</FormFeedback>
