import axios from 'axios'

const ins = axios.create({
  timeout: 120000, // milliseconds
  headers: { 'X-REQUESTED-WITH': 'XMLHttpRequest' },
  xDomain: true,
  withCredentials: true
})

class ApiClient {
  constructor(options = {}) {
    this.config = {}
    this.config.ssl = options.ssl || false
    this.config.cache = options.cache || false
    this.config.token = options.token || ''
    this.config.crosOrg = options.crosOrg || false
  }

  get(path, options = {}) {
    const method = 'get'
    const url = this.buildUrl(path, { method })
    const config = { ...options, url, method }
    return this.request(config)
  }

  post(path, data = {}, options = {}) {
    const method = 'post'
    const url = this.buildUrl(path, { method })
    const config = { ...options, url, data, method }
    return this.request(config)
  }

  delete(path, options = {}) {
    const method = 'delete'
    const url = this.buildUrl(path, { method })
    const config = { ...options, url, method }
    return this.request(config)
  }

  put(path, data = {}, options = {}) {
    const method = 'put'
    const url = this.buildUrl(path, { method })
    const config = { ...options, url, data, method }
    return this.request(config)
  }

  patch(path, data = {}, options = {}) {
    const method = 'patch'
    const url = this.buildUrl(path, { method })
    const config = { ...options, url, data, method }
    return this.request(config)
  }

  request(config = {}) {
    return this.httpClient.request(config).catch(error => {
      const response = error.response
      if (!response) {
        return Promise.reject(error)
      }
      return Promise.reject(response)
    })
  }

  get httpClient() {
    const headers = ins.defaults.headers
    if (this.config.token) {
      headers['X-CSRF-TOKEN'] = this.config.token
    } else {
      if (headers['X-CSRF-TOKEN']) {
        delete headers['X-CSRF-TOKEN']
      }
    }
    return ins
  }

  buildUrl(path, options = {}) {
    let protocol = this.config.ssl ? 'https://' : 'http://'
    let url = this.config.crosOrg
      ? path
      : protocol + window.location.host + path
    if (!this.config.cache && options.method === 'get') {
      const time = new Date().getTime()
      url = `${url}?_=${time}`
    }
    return url
  }
}

export const { CancelToken, isCancel } = axios

export default ApiClient
