<script>
  import { FormGroup, ListGroup, ListGroupItem } from 'sveltestrap'

  export let errors = []
</script>

{#if errors.length > 0}
  <FormGroup>
    <ListGroup>
      {#each errors as error}
        <ListGroupItem color="danger">{error}</ListGroupItem>
      {/each}
    </ListGroup>
  </FormGroup>
{/if}
